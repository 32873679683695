<template>
	<v-card>
		<v-form ref="form" v-model="valid" lazy-validation>
			<v-bottom-navigation
				:value="value"
				color="white"
				v-model="value"
				:background-color="color"
				dark
				height="auto"
				width="99%"
				style="margin-left: 0.5%; margin-top: -40px"
			>
				<v-row class="text-left pa-2">
					<v-col
						cols="12"
						md="4"
						sm="4"
						class="offset-md-3 text-h4 font-weight-light my-2"
					>
						Número de Certificado:
						<b>{{ editedItem.formulario.numero_certificado }}</b>
					</v-col>

					<v-col cols="12" md="4" sm="4" class="text-h4 font-weight-light my-2">
						Chasis: <b>{{ editedItem.formulario.chasis }}</b>
					</v-col>
				</v-row>
			</v-bottom-navigation>
			<v-divider width="99%" style="margin-left: 0.5%"></v-divider>

			<v-bottom-navigation
				:value="value"
				color="white"
				v-model="value"
				:background-color="color"
				dark
				height="auto"
				width="99%"
				class=""
				style="margin-left: 0.5%"
			>
				<v-card
					v-for="(opcion, index) in pdfs"
					:key="index"
					v-show="opcion.show"
					elevation="0"
					class="ma-0 mb-2 mt-2"
					style="width: 120px; border-radius: 0px !important"
					:class="
						pdfs.length - 1 !== index
							? 'b-right ' + $cv('principal')
							: $cv('principal')
					"
				>
					<p
						class="text-h5 text-center pt-0 mb-2"
						style="line-height: 1.2"
						v-html="opcion.btn"
					></p>
					<div class="text-center">
						<a style="width: 60px !important"
							><v-icon @click="pdfLoad(opcion.id)">mdi-file-pdf-box</v-icon></a
						>
						<a x-small style="width: 60px !important"
							><v-icon @click="pdfLoad(opcion.id, true)"
								>mdi-download</v-icon
							></a
						>
					</div></v-card
				>
			</v-bottom-navigation>

			<div>
				<iframe
					v-if="pdfSrc != null"
					:src="pdfSrc"
					width="99%"
					height="1400"
					style="margin-left: 0.5%"
				></iframe>
				<div v-else style="min-height: 250px"></div>
			</div>

			<v-row>
				<v-col class="text-right" cols="12" md="12" sm="12">
					<v-btn color="red" @click="$goBack()">Volver</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</v-card>
</template>
<style scoped>
.border-bottom-cell {
	border-bottom: 2px dotted #c2c2c2;
	background: #eee;
	margin-top: 5px;
	padding-top: 5px;
	padding-bottom: 0px;
	margin-bottom: 10px;
	border-radius: 5px;
}
.b-right {
	border-right: 1px solid white !important;
}
</style>
<script>
	import pdf from "vue-pdf";

	export default {
		name: "FormPdf",
		components: {
			pdf,
		},
		props: {
			tipoF01: { String, default: null },
			dialogPdf: { type: Boolean, default: false },
		},
		data: () => ({
			valid: true,
			value: 0,
			pdfSrc: null,
			editedItem: { formulario: { numero_certificado: 0 } },

			pdfs: [],
		}),

		methods: {
			async pdfLoad(type, download = false) {
				let pdf = this.pdfs[type - 1];
				try {
					this.pdfSrc = null;

					Swal.alertGetInfo(
						"Obteniendo datos para generar " + pdf.nombre + "<br><b></b>"
					);

					let id = this.$route.params.id;

					//Si es declaración jurada vacía, se envia id = 0
					if (pdf.id == 7) {
						id = 0;
					}
					let route = pdf.url;

					const response = await this.$axiosApi.pdfLoad(route, id, download);

					Swal.close();

					if (download) {
					} else {
						this.pdfSrc = response + "#zoom=100%";
					}
				} catch (error) {
					Swal.alertError(
						"Error al generar <br>" + pdf.nombre,
						"No pudimos generar el formulario por favor intenta nuevamente o contacta al administrador"
					);
					console.error("Error al cargar el PDF:", error);
				}
			},

			async editData(id) {
				let titleAlert = "Buscando información del trámite ";
				Swal.alertGetInfo(titleAlert);

				await this.$axiosApi
					.getById("showFormulario", id)
					.then((r) => {
						//Swal.close();

						this.editedItem = r.data.data;

						let showF12 = true;
						let showDDJJ = false;

						if (this.editedItem.formulario.requiere_f12 == "1") {
							showF12 = false;
						}

						//Si existe persona jurídica y tiene apoderados, se muestra DDJJ para descargar
						for (let i = 0; i < this.editedItem.titulares.length; i++) {
							const titular = this.editedItem.titulares[i];
							if (titular.personType.id == 2 && titular.apoderados > 0) {
								showDDJJ = true;
							}
						}

						this.pdfs = [
							{
								id: 1,
								nombre: "Cédula Autorizado",
								btn: "Cédula<br>Autorizado",
								url: "aseguradoras_pdf",
								show: true,
							},
							{
								id: 2,
								nombre: "Cédula Autorizado Vacio",
								btn: "Cédula<br>Autor. Vacio",
								url: "aseguradoras_pdf",
								show: true,
							},
							{
								id: 3,
								nombre: "Formulario 01",
								btn: "Formulario<br>01",
								url: "formulario01Pdf",
								show: true,
							},
							{
								id: 4,
								nombre: "Formulario 12",
								btn: "Formulario<br>12",
								url: "formulario12Pdf",
								show: showF12,
							},
							{
								id: 5,
								nombre: "Formulario Enmienda",
								btn: "Formulario<br>Enmienda",
								url: "formulario_enmienda",
								show: true,
							},
							{
								id: 6,
								nombre: "Declaración Jurada",
								btn: "Declaración<br>Jurada",
								url: "ddjjPdf",
								show: showDDJJ,
							},
							{
								id: 7,
								nombre: "Declaración Jurada Vacía",
								btn: "Declaración<br>Jurada Vacía",
								url: "ddjjPdf",
								show: true,
							},
							{
								id: 8,
								nombre: "Boleta EPagos",
								btn: "Boleta<br>EPagos",
								url: "aseguradoras_pdf",
								show: true,
							},
						];

						Swal.close();
					})
					.catch(function (error) {
						//Swal.close();
						this.$respuesta(this, error, 3, true);
					});
			},
			close() {
				this.$emit("send-message");
			},
		},
		computed: {
			color() {
				return this.$cv("primary");
			},
		},
		created() {
			console.log("Componente Pdf creado");
		},

		watch: {},

		mounted() {
			this.editData(this.$route.params.id);

			this.$hideMenu();
		},
	};
</script>
